import { CANDIDATE_SOURCING_MODULE, RECRUITMENT_CONNECT_MODULE, ROLE_TENANT_ADMIN, ROLE_GLOBAL_ADMIN, ROLE_VIEW_ONLY, ROLE_USER, ALLOW_USER_CREATION } from "../constants";
import store from "../redux/store";
import { TenantObject } from "../types";
import { getIndex } from "./app-helper";
import { getFeatureConfigurationIsEnabled } from "./feature-configuration-helper";

export function getTenantRole(tenants: TenantObject[], tenantAlias?: string, selectedUserProfile?: string) {
  let role = "";
  tenants.forEach((tenant) => {
    if (tenant.alias === tenantAlias) {
      if (selectedUserProfile) {
        role = selectedUserProfile;
      } else {
        role = tenant?.roles?.length ? tenant.roles[0] : "";
      }
    }
  });
  return role;
}

export function getAllCurentTenantRoles(tenants: TenantObject[], tenantAlias?: string) {
  let role: string[] = [];
  tenants.forEach((tenant) => {
    if (tenant.alias === tenantAlias) role = tenant.roles;
  });
  return role;
}

export function getAdminPermissions(tenants: TenantObject[], tenantAlias: string) {
  let permissions = false;
  tenants.forEach((tenant) => {
    if (tenant.alias === tenantAlias)
      if (
        tenant.permissions?.adminPanel?.categories ||
        tenant.permissions?.adminPanel?.languages ||
        tenant.permissions?.adminPanel?.users ||
        tenant.permissions?.adminPanel?.templates ||
        tenant.permissions?.adminPanel?.tenants ||
        tenant.permissions?.adminPanel?.terms ||
        tenant.permissions?.adminPanel?.clientStats ||
        tenant.permissions?.adminPanel?.recruiterStats ||
        tenant.permissions?.adminPanel?.candidateFeedback ||
        tenant.permissions?.adminPanel?.videoQuestions ||
        tenant.permissions?.adminPanel?.xpertCatalog ||
        tenant.permissions?.adminPanel?.videoLibrary ||
        tenant.permissions?.adminPanel?.screeningQuestions ||
        tenant.permissions?.adminPanel?.tenantTags ||
        tenant.permissions?.adminPanel?.calendars ||
        tenant.permissions?.adminPanel?.clients ||
        tenant.permissions?.adminPanel?.jobdescriptionsections ||
        tenant.permissions?.adminPanel?.jobDescriptionBoards ||
        // CS
        tenant.permissions?.userModules?.candidateSourcing
      ) {
        permissions = true;
      }
  });
  return permissions;
}

export function getUserAllowedModules() {
  if (isSuperAdmin()) return { [CANDIDATE_SOURCING_MODULE]: true, [RECRUITMENT_CONNECT_MODULE]: true, [ALLOW_USER_CREATION]: true };
  const tenants = store.getState().tenant.tenants;
  const tenantAlias = store.getState()?.tenant?.selectedTenant?.alias;
  const tenant = tenants?.find((_tenant) => _tenant?.alias === tenantAlias);
  if (!tenants || !tenantAlias || !tenant) return {}; //the superadmin prelogin logic is so-so
  const permissions: any = {
    [CANDIDATE_SOURCING_MODULE]: tenant?.permissions?.userModules?.candidateSourcing === true,
    [RECRUITMENT_CONNECT_MODULE]: tenant?.permissions?.userModules?.recruitmentConnect === true,
    [ALLOW_USER_CREATION]: tenant?.permissions?.customActions?.userCreation === true,
  };
  return permissions;
}

export function getInitials(fullname: string) {
  let initials = "";
  if (fullname) {
    const parts = fullname.split(" ");
    const firstInitial = parts[0] ? parts[0][0] : "";
    let secondInitial = "";
    if (parts.length >= 2 && parts[1]) secondInitial = parts[1][0];
    initials = firstInitial + secondInitial;
  }
  return initials;
}

export function isSuperAdmin(): boolean {
  const _isSuperAdmin = store?.getState()?.tenant?.selectedUserProfile === ROLE_GLOBAL_ADMIN;
  return _isSuperAdmin;
}

export function isGlobalUser(): boolean {
  const _isUserRole = store?.getState()?.tenant?.selectedUserProfile === ROLE_USER;
  const _isGlobalUser = store?.getState()?.tenant?.isGlobalUser === true;

  return _isUserRole && _isGlobalUser;
}

export function isAdmin(): boolean {
  const tenants = store.getState()?.tenant?.tenants;
  const tenantStore = store?.getState()?.tenant?.selectedTenant;
  const _isAdmin = getTenantRole(tenants, tenantStore?.alias) === ROLE_TENANT_ADMIN || store?.getState()?.tenant?.selectedUserProfile === ROLE_TENANT_ADMIN;
  return _isAdmin;
}

export function isAdminOrSuperAdmin(): boolean {
  const _isAdminOrSuperAdmin = isAdmin() || isSuperAdmin();
  return _isAdminOrSuperAdmin;
}

export function isViewOnlyUser(): boolean {
  const tenantStore = store?.getState()?.tenant;
  const external = store?.getState()?.auth?.external || null;
  const tenant = tenantStore?.selectedTenant;
  const tenants = tenantStore?.tenants;
  const allRoles = getAllCurentTenantRoles(tenants, tenant?.alias);
  return allRoles?.includes(ROLE_VIEW_ONLY) || external;
}

export function isExternal(): boolean {
  return store?.getState()?.auth?.external || null;
}

export function onlyAdminsCanSeeData(): boolean {
  //this feature means users should not be able to see data from other branches
  const onlyAdminsCanSeeAllProccessesJDsAndJPsFeature = getFeatureConfigurationIsEnabled("General Settings", "only-admins-data");
  return onlyAdminsCanSeeAllProccessesJDsAndJPsFeature === true && !isAdminOrSuperAdmin();
}

export function hasAdminPermission(permission: any): boolean {
  const tenants = store.getState()?.tenant?.tenants as any;
  const tenantAlias = store.getState()?.tenant?.selectedTenant?.alias;
  const tenantIndex = getIndex(tenantAlias, tenants);
  const _adminPermission = tenants?.[tenantIndex]?.permissions?.adminPanel?.[permission] === true;
  return _adminPermission;
}

export function currentUserId() {
  const _currentUserID = store?.getState()?.tenant?.userId;
  return _currentUserID;
}

export function currentUserName() {
  const _currentUserName = store?.getState()?.tenant?.userName;
  return _currentUserName;
}
