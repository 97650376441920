import React, { ReactElement, useEffect, useState } from "react";
import { SelectFilter, SelectFilterOption } from "../../../../shared/forms";
import { CopilotActionSteps } from "../copilot-actions";
import { Loader } from "@adeccoux/tag-ds";

/**
 * Component that renders the multi select answer type
 */
const MultiSelectAnswer: React.FC<any> = (props: any): ReactElement => {
  const { dialogStep, triggerAction } = props;

  const [answerOptions, setAnswerOptions] = useState<any[]>();
  const [inputValue, setInputValue] = useState<any[]>([]);

  useEffect(() => {
    if (dialogStep?.answers) {
      const isPredefinedAnswers = Array.isArray(dialogStep?.answers);
      // If answers is already a predefined array, set it as the final options, else, fetch them
      if (isPredefinedAnswers) setAnswerOptions(dialogStep?.answers);
      else fetchAnswers(dialogStep?.answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogStep]);

  async function fetchAnswers(answersFunction: Function) {
    try {
      const answers = await answersFunction();
      setAnswerOptions(answers);
    } catch (e) {
      setAnswerOptions([]);
    }
  }

  function handleSelectChange(e: any) {
    const value = e?.target?.value;
    if (Array.isArray(value)) setInputValue(value);
    else {
      let listOfValues = [...inputValue];
      listOfValues?.push(value);
      setInputValue(listOfValues);
    }
  }

  function handleSubmit(nextStepId?: string) {
    triggerAction(inputValue, nextStepId);
  }

  const renderAnswerContainer = (_dialogStep: CopilotActionSteps, _answerOptions?: any[]) => {
    if (!_answerOptions) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <SelectFilter name={_dialogStep?.id} multiple={true} id={_dialogStep?.id} onChange={(e: any) => handleSelectChange(e)}>
            {_answerOptions?.map((answer: any) => (
              <SelectFilterOption key={answer?.value ?? answer?.key} value={answer?.value ?? answer?.label} label={answer?.label} />
            ))}
          </SelectFilter>
          <button className="button-icon ml1" disabled={!inputValue?.length} onClick={() => handleSubmit()}>
            <span className="material-icons">send</span>
          </button>
        </React.Fragment>
      );
    }
  };

  return <div className="flex">{renderAnswerContainer(dialogStep, answerOptions)}</div>;
};

export default MultiSelectAnswer;
