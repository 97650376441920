export function fromBase64(base64: string): string {
  return Buffer.from(base64, "base64").toString("utf-8");
}

export function xorEncryptDecrypt(text: string, key: string = ''): string {
  let result = "";
  for (let i = 0; i < text.length; i++) {
    result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return result;
}

export function toBase64(text: string): string {
  return Buffer.from(text, "utf-8").toString("base64");
}
