import { ROLE_GLOBAL_ADMIN } from "../../../../constants";
import { getFeatureConfiguration } from "../../../../helper/feature-configuration-helper";
import { getTenantRole } from "../../../../helper/role-helper";
import store from "../../../../redux/store";
import { JobApplyMethod } from "../../../../types";

/* PRIVATE PART */
interface IDisableResponse {
  fieldRelated: string | undefined;
  disabled: boolean;
}
const tenants = store.getState()?.tenant?.tenants;
const tenant = store.getState().tenant.selectedTenant;
const notChangeField = undefined;
const disableRelatedInput = (name: string, disabled: boolean, context: any) => {
  const inputComponent: any = context?.inputs?.find((a: any) => a.props.name === name && a._isMounted);
  if (inputComponent) {
    // Review the option when it is true in props and not in related disabled
    inputComponent.setState({ disabled: disabled || inputComponent.props.disabled });
    // This line below just to reset the field in case germany ask for when setting field disabled
    //inputComponent.setState({ value: null });
  }
};
/* END PRIVATE PART */

/* BASE FUNCTION PART */
export async function checkDisabledInput(inputComponent: any, context: any) {
  const relatedDisables = inputComponent.props.disableRelated || [];
  for await (const relatedDisable of relatedDisables) {
    if (typeof relatedDisable === "string") {
      let disabledRelated: IDisableResponse;
      switch (relatedDisable) {
        case "dataProdurementOrderGFLORelated":
        case "dataCollectiveAgreementRelated":
        case "dataCollectiveAgreementNameRelated":
        case "dataApplicationMethodRelated":
          disabledRelated = relatedGermanFederalLabourOffice(inputComponent, context, relatedDisable);
          break;
        case "dataClientName":
        case "dataConfidential":
          disabledRelated = disableClientJobPool(inputComponent, context, relatedDisable);
          break;
        case "dataEducationRelated":
          disabledRelated = disableEducation(inputComponent, context);
          break;
        case "publishToGFLOValidClient":
          disabledRelated = validClientGFLO(inputComponent, context);
          break;
        case "externalId":
          disabledRelated = checkExternalId(inputComponent, context);
          break;
        case "applyUrlFeatureConfig":
          // Check feature configuration
          disabledRelated = checkFeatureConfig(inputComponent, context, relatedDisable);
          break;
        case "dataEstimatedEndDate":
          disabledRelated = disableEndDate(inputComponent, context);
          break;
        case "dataFunction":
          disabledRelated = disableDataFunction(inputComponent, context);
          break;
        default:
          disabledRelated = { fieldRelated: notChangeField, disabled: false };
      }
      if (typeof disabledRelated?.fieldRelated !== "undefined") {
        // Disable the related field
        disableRelatedInput(disabledRelated.fieldRelated, disabledRelated.disabled, context);
      }
    }
  }
}
/* END BASE FUNCTION PART */

/* FUNCTIONS PART */
const relatedGermanFederalLabourOffice = (input: any, _context: any, type: string) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  const value = input.state.value;
  result.fieldRelated = type.replace("Related", "");
  if (value !== "true") {
    result.disabled = true;
  }
  return result;
};

const validClientGFLO = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  const value = input.state.value;
  result.fieldRelated = "dataPublishGFLO";
  if (value === undefined || value === null || value === "") {
    result.disabled = true;
  }
  return result;
};
const disableClientJobPool = (input: any, _context: any, type: string) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  const value = input.state.value;
  result.fieldRelated = type;
  if (value === "talentPool") {
    result.disabled = true;
  }
  return result;
};

const disableEducation = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  let valueHiringType = "";
  let valuePublishGFLO = "";
  result.fieldRelated = "dataEducation";
  if (input?.props?.name === "dataHiringType") {
    valueHiringType = input.state.value;
    valuePublishGFLO = getInputValue("dataPublishGFLO", _context);
  }
  if (input?.props?.name === "dataPublishGFLO") {
    valuePublishGFLO = input.state.value;
    valueHiringType = getInputValue("dataHiringType", _context);
  }
  if (valueHiringType === "EEDECT001" && valuePublishGFLO === "true") {
    result.disabled = false;
  } else {
    result.disabled = true;
  }
  return result;
};

// ExternalId filled means comming from InFO, so we disable
const checkExternalId = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  if (input.context.props?.values?.externalId) {
    result.fieldRelated = input.props.name;
    const isTester = getTenantRole(tenants, tenant?.alias) === ROLE_GLOBAL_ADMIN || store?.getState()?.tenant?.selectedUserProfile === ROLE_GLOBAL_ADMIN;
    if (isTester) {
      result.disabled = false;
    } else {
      result.disabled = true;
    }
  }
  return result;
};

const checkFeatureConfig = (input: any, _context: any, type: string) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  const applicationMethod = getFeatureConfiguration("General Settings", "job-apply-method");
  if (applicationMethod?.isEnabled && applicationMethod?.config?.applyMethod !== JobApplyMethod.standaloneApplyPage) {
    result.fieldRelated = type;
    result.disabled = true;
  }
  return result;
};

const disableEndDate = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  let valueContractType = "";
  let valueEstimatedStartDate = "";
  //field affected by the changes
  result.fieldRelated = "dataEstimatedEndDate";
  if (input?.props?.name === "dataContractType") {
    //get values of the current changed input and the start date
    valueContractType = input.state.value;
    valueEstimatedStartDate = getInputValue("dataEstimatedStartDate", _context);
  }
  if (input?.props?.name === "dataEstimatedStartDate") {
    //get values of the current changed input and the contract type
    valueEstimatedStartDate = input.state.value;
    valueContractType = getInputValue("dataContractType", _context);
  }
  //estimated end date able if there is a temporary contract type and a estimated start date
  if (valueContractType === "TEMP" && valueEstimatedStartDate) {
    result.disabled = false;
  } else {
    result.disabled = true;
  }

  return result;
};

const disableDataFunction = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, disabled: false };
  let valueDataFunctionGroup = "";
  //field affected by the changes
  result.fieldRelated = "dataFunction";
  if (input?.props?.name === "dataFunctionGroup") {
    valueDataFunctionGroup = input.state.value;
  }
  if (valueDataFunctionGroup) {
    result.disabled = false;
  } else {
    result.disabled = true;
  }
  return result;
};

const getInputValue = (name: string, context: any) => {
  return context?.inputs?.find((a: any) => a.props.name === name && a._isMounted)?.state?.value;
};
