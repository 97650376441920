import { MediaTypes, PhaseType, ProcessPhase } from "../../../types";

export async function copyMediaContents(phases: ProcessPhase[], isCopy?: boolean, oldId?: string, templateId?: boolean) {
  let clonedPhases = [];
  clonedPhases = phases.map((phase) => {
    const tempPhase = { ...phase };
    switch (tempPhase.type) {
      case PhaseType.start:
      case PhaseType.end:
        if (tempPhase.mediaType !== MediaTypes.externalVideo) {
          if (isCopy) {
            tempPhase.loadFromProcess = oldId;
          }
          tempPhase.loadFromTemplate = templateId || false;
          tempPhase.temp = true;
        }
        break;
      case PhaseType.video:
        if (tempPhase.video) {
          const videoContent = { ...tempPhase.video };
          if (videoContent.mediaType !== MediaTypes.externalVideo) {
            if (isCopy) {
              videoContent.loadFromProcess = oldId;
            }
            videoContent.temp = true;
            videoContent.loadFromTemplate = templateId;
          }
          tempPhase.video = videoContent;
        }
        break;
      case PhaseType.screeningQuestions:
        if (tempPhase.questions) {
          let tempQuestions = [...tempPhase.questions];
          tempPhase.questions = tempQuestions.map((question) => {
            let tempQuestion = { ...question };
            if (tempQuestion.mediaUrls) {
              let tempMediaUrls = [...tempQuestion.mediaUrls];
              tempQuestion.mediaUrls = tempMediaUrls.map((media) => {
                let tempMedia = { ...media };
                if (tempMedia.mediaType !== MediaTypes.externalVideo) {
                  if (isCopy) {
                    tempMedia.loadFromProcess = oldId;
                  }
                  tempMedia.loadFromTemplate = templateId;
                  tempMedia.temp = true;
                }
                return tempMedia;
              });
            }
            return tempQuestion;
          });
        }
        break;
      case PhaseType.videoInterview:
        if (tempPhase.questions) {
          let tempQuestions = [...tempPhase.questions];
          tempPhase.questions = tempQuestions.map((question) => {
            let tempQuestion = { ...question };
            if (tempQuestion.videoUrl) {
              if (isCopy) {
                tempQuestion.loadFromProcess = oldId;
              }
              tempQuestion.loadFromTemplate = templateId;
              tempQuestion.temp = true;
            }
            return tempQuestion;
          });
        }
        break;
    }
    return tempPhase;
  });

  return clonedPhases;
}
