export const _env = {
  REACT_APP_ENV_PREFIX: process.env.REACT_APP_ENV_PREFIX,
  REACT_APP_AAMBE_URL: process.env.REACT_APP_AAMBE_URL,
  REACT_APP_CANDIDATEBACKEND_URL: process.env.REACT_APP_CANDIDATEBACKEND_URL,
  REACT_APP_STORAGE_URL: process.env.REACT_APP_STORAGE_URL,
  REACT_APP_APIM_KEY: process.env.REACT_APP_APIM_KEY,
  REACT_APP_APIM_AAMBE_KEY: process.env.REACT_APP_APIM_AAMBE_KEY,
  REACT_APP_INST_KEY: process.env.REACT_APP_INST_KEY,
  REACT_APP_BING_MAPS_KEY: process.env.REACT_APP_BING_MAPS_KEY,
  REACT_APP_O365_TENANTID: process.env.REACT_APP_O365_TENANTID,
  REACT_APP_O365_CLIENTID: process.env.REACT_APP_O365_CLIENTID,
  REACT_APP_O365_URL: process.env.REACT_APP_O365_URL,
  FE_RELEASE_VERSION: process.env.FE_RELEASE_VERSION,
  HOTJAR_ID: process.env.HOTJAR_ID,
  REACT_APP_AAMBE_VERIFICATION_LINK_URL: process.env.REACT_APP_AAMBE_VERIFICATION_LINK_URL,
  REACT_APP_AZURE_MAP_SUBSCRIPTION_KEY: process.env.REACT_APP_AZURE_MAP_SUBSCRIPTION_KEY,
  REACT_APP_GOOGLE_ANALYTICS_TAG_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID,
  REACT_APP_ENCODER_DECODER_KEY: process.env.REACT_APP_ENCODER_DECODER_KEY,
};
