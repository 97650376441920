import React, { ReactElement } from "react";
import { ResultsI, ROLE_GLOBAL_ADMIN } from "../../../../constants";
import { getFeatureConfiguration } from "../../../../helper/feature-configuration-helper";
import { getDateFormatFromFeatureConfiguration } from "../../../../helper/date-format-helper";
import moment from "moment";

interface TableComponent {
  results: ResultsI;
  columns: any[];
}

const CammioReportTable: React.FC<TableComponent> = (props): ReactElement => {
  const { results, columns } = props;
  const dateFormat = getDateFormatFromFeatureConfiguration();
  const recruiterProfileFeature = getFeatureConfiguration("General Settings", "recruiter-profile");
  const dayHoursFormat = recruiterProfileFeature?.config?.timeFormat === "halfDay" ? "hh:mm" : "HH:mm";

  const renderCellContent = (col: any, res: any) => {
    switch (col.columnKey) {
      case "lastlogin":
        return res[col.columnKey] ? renderElement(getDate(res[col.columnKey])) : "-";
      case "removed":
      case ROLE_GLOBAL_ADMIN:
        return res[col.columnKey] ? <span className="material-icons">done</span> : "";
      case "email":
      case "name":
        return res[col.columnKey];
      default:
        return renderElement(res[col.columnKey] ? res[col.columnKey] : "0");
    }
  };

  const renderElement = (displayElement: any) => {
    return parseInt(displayElement) > 0 ? <div>{displayElement}</div> : "0";
  };

  function getDate(date: any) {
    return moment(date)
      .format(dateFormat + " " + dayHoursFormat)
      .toString();
  }

  return (
    <>
      {results?.data.map((res: any, index: number) => {
        return (
          <tr key={index} className={"-row " + (!res["hasData"] ? "-disable" : "")}>
            {columns.map((col) => (
              <td key={col.columnKey} id={"cammio-report-cell-" + col.columnKey + "-" + index}>
                {renderCellContent(col, res)}
              </td>
            ))}
          </tr>
        );
      })}
    </>
  );
};
export default CammioReportTable;
