import * as Api from "../api";
import { matchPath } from "react-router-dom";

import { TenantStorage } from "./tenant-storage";
import {
  setDefaultTenant,
  setLanguage,
  setLoginUser,
  setTenant,
  setTenantsResponse,
  setTenantUpdated,
  setClientLogo,
  setLoginUserEmail,
  setUserIsGlobalUser,
  setLoginUserPhone,
  setLoginReleaseVersion,
} from "../redux/slices/tenantSlice";

import { DEFAULT_LANGUAGE } from "../constants";
import { Branch, SelectedTenantObject, TenantObject, TenantResponse } from "../types";

import { logout } from "./auth-helper";
import { getBrand, getCountry } from "./url-helper";
import { getCurrentRoute, getFullPath, NavItem } from "../utilities/navigation";
import { applyThemeClientBrandResult, applyThemeCobrandResult } from "./cobrand-client-brand-helper";
import { BROWSER_LANGUAGE, getLocale, iso2ToLongLanguage, setCurrentLocale } from "./locale-utils";

export function manageInternalTenant(history: any, dispatch: any, tenant: SelectedTenantObject, currentRoute: NavItem) {
  const returnedMatchPath = matchPath(history.location.pathname, { path: currentRoute.url, exact: true, strict: false });
  const processId = (returnedMatchPath?.params as any)?.id_process;
  // Get sure the route is for JD section === CANDIDATE_SOURCING_SECTION
  const jdId = isJDPath(returnedMatchPath?.path) ? (returnedMatchPath?.params as any)?.id : null;
  const tenantAlias = (returnedMatchPath?.params as any)?.tenant_alias;
  if (currentRoute?.id === "LOGIN" || currentRoute?.id === "LOGIN_TOKEN") {
    //Login page, if there is not tenant set, set the default
    if (tenant.tenant < 0) dispatch(setDefaultTenant());
    history.push(history.location.pathname + history.location.hash + history.location.search, history.location.state);
  } else if (processId) {
    checkTenantProcessAndFetchTenantInfo(processId, false, history, dispatch);
  } else if (jdId) {
    checkTenantProcessAndFetchTenantInfo(jdId, true, history, dispatch);
  } else {
    fetchTenantInfo(history, dispatch, tenantAlias);
  }
  dispatch(setTenantUpdated(true)); // FOR WHAT?
}

/**
 * This is for Process and jobDescription
 */
async function checkTenantProcessAndFetchTenantInfo(id: string, isJd: boolean, history: any, dispatch: any) {
  try {
    const tenantResponse: TenantResponse = await Api.fetchTenantAndUserInfo();
    const promise = isJd ? Api.getJobDescriptionTenant(id) : Api.getProcessTenant(id);
    promise
      .then((res) => {
        // Get tenant from storage
        const tenantStorage = new TenantStorage();
        const storedTenant = tenantStorage.getTenant();
        // Check tenant and branch
        const sameTenant = storedTenant?.alias === res.tenant?.alias;
        const sameBranch = res.branch ? storedTenant?.branchId === res.branch?.id : true;
        if (!sameTenant || !sameBranch) {
          const coincidentTenant = tenantResponse?.tenants.find((tenant: any) => tenant?.alias === res.tenant?.alias);
          const coincidentBranch = coincidentTenant?.branches.find((branch: any) => branch?.branchId === res.branch?.id);
          // If exist branch and we find tenant branch OR not exist branch and we find Tenant
          if ((res.branch && coincidentTenant && coincidentBranch) || (!res.branch && coincidentTenant)) {
            // Force change tenant / branch
            onDispatchTenant(history, dispatch, tenantResponse, coincidentTenant?.alias, coincidentBranch);
          } else {
            onDispatchTenant(history, dispatch, tenantResponse);
          }
        } else {
          onDispatchTenant(history, dispatch, tenantResponse);
        }
      })
      .catch((e: Error) => {
        console.error(e);
        onDispatchTenant(history, dispatch, tenantResponse);
      });
  } catch (e) {
    logout(history, "", dispatch, () => {
      dispatch(setDefaultTenant());
    });
  }
}

function onDispatchTenant(history: any, dispatch: any, tenantResponse: TenantResponse, tenantAlias?: string, branch?: Branch) {
  dispatch(setLoginUser(tenantResponse.name, tenantResponse.id, tenantResponse.properties, tenantResponse.userTenant));
  dispatch(setLoginUserEmail(tenantResponse.email));
  dispatch(setUserIsGlobalUser(tenantResponse.isGlobalUser || false));
  dispatch(setLoginUserPhone(tenantResponse?.phone || ""));
  dispatch(setLoginReleaseVersion(tenantResponse?.releaseVersion || "", tenantResponse?.frontendVersion || ""));
  if (tenantResponse?.tenants?.length) {
    dispatch(setTenantsResponse(tenantResponse.tenants, tenantAlias, branch));
  } else {
    logout(history, "No tenants avaible for the user", dispatch, () => {
      dispatch(setDefaultTenant());
    });
  }
}

export function redirectToNotFound(history: any, e: any) {
  history.push(`/not-found?url=${window.location.href}`);
}

function getTenantAlias(fullPath: string, pathname: string, search: string): { alias: string; brand: string; country: string } {
  const tenantAliasIndex = fullPath.split("/").indexOf(":tenant_alias");
  const tenant = {
    alias: "",
    brand: "",
    country: "",
  };
  if (tenantAliasIndex !== -1) {
    tenant.alias = pathname.split("/")[tenantAliasIndex];
  } else {
    const urlSearchParams = new URLSearchParams(search);
    const country = getCountry(urlSearchParams.get("country"));
    if (urlSearchParams) {
      tenant.alias = urlSearchParams.get("tenant") || urlSearchParams.get("tenantAlias") || urlSearchParams.get("tenantId") || "";
      tenant.brand = getBrand(country, urlSearchParams.get("brand")) || "";
      tenant.country = country || "";
    }
  }
  return tenant;
}
export async function managePublicTenant(history: any, dispatch: any, currentRoute: any): Promise<void> {
  // Public pages
  const { alias, brand, country } = getTenantAlias(getFullPath(currentRoute.id), history.location.pathname, history.location.search);
  if (alias || (brand && country)) {
    const tenantInfo = await fetchTenantPublicInfo(alias, brand, country, history);
    const tenantAlias = alias || tenantInfo.alias;
    extractTenantPublicInfo(tenantInfo, tenantAlias, history, dispatch);
  } else redirectToNotFound(history, "Missing tenant alias or info");
}

async function fetchTenantPublicInfo(tenantAlias = "", brandDecoded = "", country = "", history?: any): Promise<any> {
  let responseTenantPublicData = null;
  if (tenantAlias) {
    responseTenantPublicData = Api.getTenantPublicInfo(tenantAlias);
  } else {
    responseTenantPublicData = Api.getTenantPublicInfoByBrandAndCountry(brandDecoded, country);
  }
  return responseTenantPublicData
    .then((data) => {
      return data;
    })
    .catch((e) => {
      redirectToNotFound(history, e);
    });
}

async function extractTenantPublicInfo(tenantInfo: any, tenantAlias: string, history: any, dispatch: any) {
  try {
    if (history.location.pathname.startsWith("/process/") || history.location.pathname.startsWith("/confirmation/") || getCurrentRoute(history.location.pathname)?.id === "CANDIDATE_WEB_REDIRECT") {
      const processId = history.location.pathname.split("/")[3];
      const result = await Api.fetchPublicProcess(processId, tenantAlias);
      applyTheme(result, dispatch, tenantInfo, tenantAlias);
    } else if (getCurrentRoute(history.location.pathname)?.id === "QUICK_APPLY_WEB_PUBLIC") {
      const jdId = history.location.pathname.split("/")[3];
      const result = await Api.fetchPublicJobDescriptionProcessTheme(jdId, tenantAlias);
      applyTheme(result, dispatch, tenantInfo, tenantAlias);
    } else if (getCurrentRoute(history.location.pathname)?.id === "QUICK_APPLY_VERIFICATION_WEB_PUBLIC") {
      const params = new URLSearchParams(history.location.search);
      const previousQAPURL = params.get("url") || "";
      const jdId = previousQAPURL.split("/")[3];
      const result = await Api.fetchPublicJobDescriptionProcessTheme(jdId, tenantAlias);
      applyTheme(result, dispatch, tenantInfo, tenantAlias);
    } else if (getCurrentRoute(history.location.pathname)?.id === "SERVICE_ASSESSMENT") {
      const serviceId = history.location.pathname.split("/")[3];
      const personId = history.location.pathname.split("/")[4];
      const result = await Api.fetchServiceAssesments(serviceId, personId);
      applyTheme(result, dispatch, tenantInfo, tenantAlias);
    } else {
      applyTenant(tenantInfo?.id, tenantInfo?.languages, tenantAlias, dispatch, tenantInfo?.theme, tenantInfo?.configuration, tenantInfo?.additionalData);
    }
  } catch (e) {
    console.error("app-helper.extractTenantPublicInfo", e);
    history.push(`/not-found?url=${window.location.href}`);
  }
}

export function applyTheme(result: any, dispatch: any, tenantInfo: any, tenantAlias: string) {
  let themeToApply = tenantInfo?.theme;
  if (result?.subBrand) {
    themeToApply = result.subBrand.name;
  } else if (result?.coBrand) {
    themeToApply = result?.coBrand?.variables?.name;
    applyThemeCobrandResult(result);
    if (result?.coBrand?.client?.logoId) dispatch(setClientLogo(result?.coBrand?.client?.logoId));
  }
  if (result?.clientBrand?.logoId) dispatch(setClientLogo(result.clientBrand.logoId)); //The client logo is prioritario vs cobrand logo.
  if (result?.clientBrand) applyThemeClientBrandResult(result);
  if (result?.clientBrand?.brandData?.primaryLogo) dispatch(setClientLogo(result?.clientBrand?.brandData?.primaryLogo, result?.clientBrand?.brandData?.secondaryLogo, true));
  applyTenant(tenantInfo?.id, tenantInfo?.languages, tenantAlias, dispatch, themeToApply, tenantInfo?.configuration, tenantInfo?.additionalData);
}

export function applyTenant(id: number, languages: any, tenantAlias: string, dispatch: any, theme: any, configuration: any, additionalData: any) {
  dispatch(setTenant(id, tenantAlias, theme, languages, undefined, configuration, additionalData));
  document.getElementById("root")?.setAttribute("theme", theme);
}

async function fetchTenantInfo(history: any, dispatch: any, paramsTenantAlias?: string) {
  try {
    const tenantResponse: TenantResponse = await Api.fetchTenantAndUserInfo();
    const tenantStorage = new TenantStorage();
    const storedTenant = tenantStorage.getTenant();
    if (paramsTenantAlias) {
      const coincidentTenant = tenantResponse?.tenants?.find((tenant: any) => tenant?.alias === paramsTenantAlias);
      if (coincidentTenant) {
        onDispatchTenant(history, dispatch, tenantResponse, paramsTenantAlias);
      } else {
        logout(history, "Tenant not founded", dispatch, () => {
          dispatch(setDefaultTenant());
        });
      }
    } else {
      const alias = storedTenant?.alias ? storedTenant?.alias : "";
      onDispatchTenant(history, dispatch, tenantResponse, alias);
    }
  } catch (e) {
    logout(history, "Error getting user information", dispatch, () => {
      dispatch(setDefaultTenant());
    });
  }
}

export function checkAndSetLocale(tenantLanguages: string[], currentLanguage: string, isPublicOrLogin: boolean | undefined, dispatch: any) {
  let languageToChange = "";
  // Get from localstorage
  const tenantStorage = new TenantStorage();
  // 1) Storage
  languageToChange = tenantStorage.getLanguage();

  // 2) Default browser - only for public pages or LOGIN screen
  if (isPublicOrLogin && (!languageToChange || !checkInTenantLanguages(tenantLanguages, languageToChange))) {
    languageToChange = iso2ToLongLanguage(BROWSER_LANGUAGE);
  }
  // 3) Default tenant
  if (!languageToChange || !checkInTenantLanguages(tenantLanguages, languageToChange)) {
    languageToChange = tenantLanguages[0]; // Default tenant
  }
  // Standarizate language
  languageToChange = getLocale(languageToChange)?.locale || DEFAULT_LANGUAGE;
  if (languageToChange !== currentLanguage) {
    setCurrentLocale(languageToChange);
    dispatch(setLanguage(languageToChange));
  }
}

function checkInTenantLanguages(languages: string[] = [], language: string = "") {
  return languages.includes(language);
}

export function generateShowNameBranch(name: string, costCenter?: string): string {
  let reName = name || "";
  if (costCenter) reName = `${costCenter} - ${reName}`;
  return reName;
}

export function getIndex(alias: string, tenants: TenantObject[]): number {
  return tenants?.findIndex((tenantItem) => tenantItem.alias === alias);
}

function isJDPath(path: string = ""): boolean {
  return path === "/sourcing/:id" || path === "print-job-description/:id";
}
