import { userHasPermissionToModule } from "../utilities/navigation";
import { getFeatureConfigurationIsEnabled } from "./feature-configuration-helper";
import { getTenantRole } from "./role-helper";
import { ROLE_TENANT_ADMIN, ROLE_GLOBAL_ADMIN } from "../constants";
import store from "../redux/store";

export function checkSidebarOptionVisibility(option: any) {
    const tenants = store.getState().tenant.tenants;
    const isSuperAdmin = getTenantRole(tenants, store.getState().tenant.selectedTenant.alias) === ROLE_GLOBAL_ADMIN;
    const isAdmin = getTenantRole(tenants, store.getState().tenant.selectedTenant.alias) === ROLE_TENANT_ADMIN;
    let isVisible = false;
    option.modules &&
      option.modules.forEach((module: string) => {
        if (userHasPermissionToModule(module)) isVisible = true;
      });
    if (option.roles) {
      return !!option.roles.find((role: any) => {
        return (role === ROLE_GLOBAL_ADMIN && isSuperAdmin) || (role === ROLE_TENANT_ADMIN && isAdmin);
      });
    }
    if (option.feature && option.title !== "sidebar.admin.settings") {
      isVisible = getFeatureConfigurationIsEnabled(option.feature.channel, option.feature.feature);
    }
    if (option.title === "sourcing") {
        isVisible = true;
    }
    return isVisible;
  }