import { REACT_APP_GOOGLE_ANALYTICS_TAG_ID } from "../../../constants";
import store from "../../../redux/store";
import { getSessionId } from "../../../helper/auth-helper";

export function insertGoogleTag() {
  if (!REACT_APP_GOOGLE_ANALYTICS_TAG_ID) {
    console.error("Google Analytics not configurated for current environment");
    return;
  }
  const srcGTag = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GOOGLE_ANALYTICS_TAG_ID}`;
  if (!document.querySelector(`script[src="${srcGTag}"]`)) {
    const script = document.createElement("script");
    script.setAttribute("src", srcGTag);
    script.async = true;
    document.head.appendChild(script);
  }
  if (!document.querySelector(`script[id="google-tag-manager-script"]`)) {
    const script = document.createElement("script");
    script.setAttribute("id", "google-tag-manager-script");
    script.text = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${REACT_APP_GOOGLE_ANALYTICS_TAG_ID}');`;
    document.head.appendChild(script);
  }
}

export function addGoogleAnalyticsEvent(eventName: string, eventParameters: object) {
  if (!(window as any)?.gtag) {
    console.error("Google Analytics not detected");
    return;
  }
  const reduxState = store?.getState();
  const tenant = reduxState?.tenant?.selectedTenant;
  const currentRoute = reduxState?.navigation?.currentRoute;
  (window as any)?.gtag("event", eventName, {
    app_name: "Connect",
    app_module: currentRoute?.section ?? "",
    tenant_name: tenant?.name,
    user_session_id: getSessionId(),
    ...eventParameters,
  });
}
