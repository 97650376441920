import { _env } from "../config-env";
import { PhaseType } from "../types";

export const APP_NAME = "Connect";

export const DEFAULT_TENANT_ALIAS = "Adecco-Public";

export const SESSION_ID = "sessionId";
export const PROCESS_CREATION_EDIT_SCREEN_NAME = "Process Creation - Edit";
export const JOB_DESCRIPTION_CREATION_SCREEN_NAME = "Job Description Creation";
export const RC_EVENT_CREATE_PROCESS = "create_process";
export const SOURCING_EVENT_CREATE_JOB_DESCRIPTION = "create_job_description";


export const MUNICIPALITY = "Municipality";
export const CANDIDATE_SOURCING_MODULE = "candidateSourcing";
export const RECRUITMENT_CONNECT_MODULE = "recruitmentConnect";
export const ALLOW_USER_CREATION = "allowUserCreation";
export const MODALS_ID_PARENT_CREATE = "recruitment-connect-container";
export const ADECCO_SITE_REDIRECT_URL = "https://www.adecco.com";
export const SERVICE_NOW_URL = "https://adecco.service-now.com/sp?id=sc_category&sys_id=623c01a81b0aac900b73b841dd4bcb92";
export enum RecruiterActivityAction {
  edit = "edit",
  create = "create",
  draft = "draft",
  details = "details",
  funnel = "funnel",
  tags = "tags",
  cv = "cv",
  rating = "rating",
  appointment = "appointment",
  addDocs = "additionalDocs",
  close = "close",
}

export enum Container {
  internal = "internal",
  public = "public",
  landing = "landing",
}

export enum PeriodType {
  quarter = "1",
  month = "2",
}

export const INPUT_ATTACHMENT_MAX_SIZE = 100 * 1024 * 1024;
export const CV_MAX_SIZE = { literal: "3 MB", numeric: 3 * 1024 * 1024 };
export const QA_MAX_FILE_SIZE = 3; //20 from JSON will be ignored
export const CV_ALLOWED_TYPES = ".pdf, .png, .jpeg, .pptx, .docx";
export const CV_ALLOWED_TYPES_V2 =
  "application/pdf, image/png, image/jpeg, application/vnd.ms-powerpoint, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.template, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const RECRUITMENT_CONNECT_SECTION = "recruitmentConnect";
export const CANDIDATE_SOURCING_SECTION = "candidateSourcing";
export const ADMIN_SECTION = "adminPanel";
export const HOME_SECTION = "home";

export const ROLE_GLOBAL_ADMIN = "superAdmin";
export const ROLE_TENANT_ADMIN = "admin";
export const ROLE_USER = "user";
export const ROLE_GLOBAL_USER = "globalUser";
export const ROLE_VIEW_ONLY = "user-view-only";
export const ROLE_CS_ADMIN = "admin-cs";
export const ROLE_ATTRACTION = "user-talent-attraction-specialist";
export const ROLE_SOURCING = "user-talent-sourcing-specialist";
export const ROLE_SCREENING = "user-talent-screening-specialist";
export const ROLE_ASSESMENT = "user-talent-assessment-specialist";
export const ROLE_ASSESMENT_SENIOR = "user-talent-assessment-senior-specialist";

export const HEADER_TITLE: any = {
  [RECRUITMENT_CONNECT_SECTION]: "sidebar.recruitment",
  [CANDIDATE_SOURCING_SECTION]: "sidebar.sourcing",
};

export const INVITE_INTERVIEW_OPTIONS = {
  auto: "auto",
  manual: "manual",
};

export const VIDEO_INTERVIEW_EXPIRY_DAYS_LIST = Array.from(Array(32)).map((_item, index: number) => index);

export const ORDER_PROCESSNAME_ASC = "name,asc";
export const ORDER_PROCESSNAME_DESC = "name,desc";
export const ORDER_DATE_ASC = "createdAt,asc";
export const ORDER_DATE_DESC = "createdAt,desc";
export const ORDER_DATE_UPDATED_ASC = "updatedAt,asc";
export const ORDER_DATE_UPDATED_DESC = "updatedAt,desc";
export const ORDER_EXPIRATION_DATE_ASC = "expirationDate,asc";
export const ORDER_EXPIRATION_DATE_DESC = "expirationDate,desc";
export const ORDER_STATUS_ASC = "status,asc";
export const ORDER_STATUS_DESC = "status,desc";
export const ORDER_PENDING_ASC = "statsPendingValidation,asc";
export const ORDER_PENDING_DESC = "statsPendingValidation,desc";
export const ORDER_CANDIDATE_ASC = "displayName,asc";
export const ORDER_CANDIDATE_DESC = "displayName,desc";
export const ORDER_SCORE_ASC = "score,asc";
export const ORDER_SCORE_DESC = "score,desc";
export const ORDER_HIRED_ASC = "hired,asc";
export const ORDER_HIRED_DESC = "hired,desc";
export const ORDER_SKILL_ASC = "skill,asc";
export const ORDER_SKILL_DESC = "skill,desc";
export const ORDER_QUESTION_ASC = "question,asc";
export const ORDER_QUESTION_DESC = "question,desc";
export const ORDER_CLIENTS_ASC = "name,asc";

export const ORDER_AD_USER_NAME_ASC = "name,asc";
export const ORDER_AD_USER_NAME_DESC = "name,desc";
export const ORDER_AD_USER_EMAIL_ASC = "email,asc";
export const ORDER_AD_USER_EMAIL_DESC = "email,desc";
export const ORDER_AD_USER_ROLE_ASC = "role,asc";
export const ORDER_AD_USER_ROLE_DESC = "role,desc";

export const ORDER_AD_NAME_ASC = "name,asc";
export const ORDER_AD_NAME_DESC = "name,desc";

export const ORDER_TAG_NAME_ASC = "name,asc";
export const ORDER_TAG_NAME_DESC = "name,desc";

export const ORDER_UPDATED_BY_NAME_ASC = "updatedBy,asc";
export const ORDER_UPDATED_BY_NAME_DESC = "updatedBy,desc";

export const ORDER_MAIL_NAME_ASC = "name,asc";
export const ORDER_MAIL_NAME_DESC = "name,desc";
export const ORDER_MAIL_ID_ASC = "id,asc";
export const ORDER_MAIL_ID_DESC = "id,desc";
export const ORDER_MAIL_LANGUAGE_ASC = "language,asc";
export const ORDER_MAIL_LANGUAGE_DESC = "language,desc";
export const ORDER_MAIL_PROVIDER_ASC = "provider,asc";
export const ORDER_MAIL_PROVIDER_DESC = "provider,desc";
export const EMAIL_PROVIDERS = ["sendgrid", "mailjet", "mmmHub"];

export const TABLE_SIZE = 10;
export const VIDEO_TABLE_SIZE = 8;
export const QUESTION_TABLE_SIZE = 10;
export const DRAFT_EXPIRATION_TIME = 3600000;
export const PAGINATION_RANGE = 10;

export const DEFAULT_RESULTS_SIZE = 7;
export const CALENDARS_UNPAGINATED_MAX_RESULTS = 1024;
export const MULTI_RECRUITERS_MAX_RESULTS = 255;

export const ITEMS_PER_PAGE = { PROCESS_DETAILS: [10, 20, 50], HOME_PROCESSES: [7], GENERAL: [10, 25, 50, 75] };

export const FOOTER_TEXT = `© ${new Date().getFullYear()} The Adecco Group`;
export const FE_VERSION = "2.0";

export const MAX_UPLOAD_FILE_SIZE = 100000000;

export const REMINDER_MAILS_AMOUNT = [1, 2, 3];
export const REMINDER_MAILS_FRECUENCY = ["12h", "18h", "24h", "48h"];
export const REMINDER_MAILS_AMOUNT_FILTER = [
  { label: "1", key: 1 },
  { label: "2", key: 2 },
  { label: "3", key: 3 },
];
export const REMINDER_MAILS_FRECUENCY_FILTER = [
  { label: "12 Hours", key: 12 },
  { label: "18 Hours", key: 18 },
  { label: "24 Hours", key: 24 },
  { label: "48 Hours", key: 48 },
];

export const ONE_DAY_MILISECONDS = 86400000;

export const API_BASE_PATH_AAMBE = _env.REACT_APP_AAMBE_URL;
export const API_BASE_PATH = _env.REACT_APP_CANDIDATEBACKEND_URL;
export const STORAGE_BASE_PATH = _env.REACT_APP_STORAGE_URL;
export const APIM_KEY = _env.REACT_APP_APIM_KEY;
export const APIM_AAMBE_KEY = _env.REACT_APP_APIM_AAMBE_KEY;
export const SNACKBAR_TIMEOUT = 2000;
export const REACT_APP_AAMBE_VERIFICATION_LINK_URL = _env.REACT_APP_AAMBE_VERIFICATION_LINK_URL;
export const REACT_APP_AZURE_MAP_SUBSCRIPTION_KEY = _env.REACT_APP_AZURE_MAP_SUBSCRIPTION_KEY;
export const REACT_APP_GOOGLE_ANALYTICS_TAG_ID = _env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID;
export const REACT_APP_ENCODER_DECODER_KEY = _env.REACT_APP_ENCODER_DECODER_KEY;

export const CHAR_LIMIT_TYPE = 50;
export const CHAR_LIMIT_TITLE = 75;
export const CHAR_LIMIT_100 = 100;
export const CHAR_LIMIT = 250;
export const CHAR_LIMIT_800 = 800;
export const CHAR_LONG_LIMIT = 512;
export const CHAR_VERY_LONG_LIMIT = 1000;
export const WYSIWYG_MAX_CHARACTERS = 2500; //there is HTML in the input

export const RETRIES_LIST = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const ANSWERTIME_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const keysCursorMove = [35, 36, 37, 38, 39, 40];
export const keysF1_F12 = [112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123];
export const notKeysDown = [9, 16, 17, 18, 19, 20, 27, 33, 34, ...keysCursorMove, 41, 42, 43, 44, 45, 47, 91, 92, ...keysF1_F12, 144, 145];

export const NOTIFICATION_DEFAULT_TIMER = 30;

export const MAX_CANDIDATES_UPLOAD_PER_CALL = 50; // to be reverted back to 500 on task 75707

export const APPOINTMENTS_TYPES = {
  Process: "process",
  Service: "service",
};

export const MAIL_ACTIONS = {
  RESCHEDULE: "reschedule",
  RESCHEDULE_EXTRA: "reschedule-extra",
};

export const TEXT_EDITOR_MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "link"], // toggled buttons
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ],
};

// Do not change the id, because will be the reference saved on the bbdd
export const PHONE_NUMBER_TYPES = [
  {
    id: "1",
    name: "phone.number.type.france",
    regex: /^[0]{1}\d{9}$/g, // Phone that starts with 0 followed by 9 digits
    description: "phone.number.type.france.description",
  },
  {
    id: "2",
    name: "phone.number.type.us",
    regex: /^(\+1)?[2-8]\d{9}$/g, // Phone that could start with the prefix +1 followed by 10 digits starting from 2 to 8
    description: "phone.number.type.us.description",
  },
  {
    id: "3",
    name: "phone.number.type.ca",
    regex: /^(\+1)?(204|250|2\d6|2\d9|306|343|36\d|403|41\d|43\d|450|506|51\d|548|579|58\d|604|613|639|647|672|70\d|778|78\d|819|825|873|8\d7|90\d)(\d{7})$/g, // Phone that could start with one of the gropu of digits defined followed by 10 digits
    description: "phone.number.type.ca.description",
  },
];
interface FeatureConfigurationBasic {
  channel: string;
  feature: string;
  title?: string | string[];
  isEnabled?: boolean;
}

export interface FeatureConfiguration extends FeatureConfigurationBasic {
  config?: any;
  childs?: FeatureConfiguration[];
  configType?: string;
  show?: FeatureConfigurationBasic;
  emptyConfig?: any;
  type?: string;
}

export const ADMIN_PERMISSIONS = [
  { label: "administrator.user.permissions.users", value: "users" },
  { label: "administrator.user.permissions.templates", value: "templates" },
  { label: "administrator.user.permissions.languages", value: "languages" },
  { label: "administrator.user.permissions.terms", value: "terms" },
  { label: "administrator.user.permissions.cookieBanner", value: "cookieBanner" },
  { label: "administrator.user.permissions.tenants", value: "tenants" },
  { label: "administrator.user.permissions.categories", value: "categories" },
  { label: "administrator.user.permissions.skills", value: "skills" },
  { label: "administrator.user.permissions.clientStats", value: "clientStats" },
  { label: "administrator.user.permissions.recruiterStats", value: "recruiterStats" },
  { label: "administrator.user.permissions.candidateFeedback", value: "candidateFeedback" },
  { label: "administrator.user.permissions.videoQuestions", value: "videoQuestions" },
  { label: "administrator.user.permissions.xpertCatalog", value: "xpertCatalog" },
  { label: "administrator.user.permissions.videoLibrary", value: "videoLibrary" },
  { label: "administrator.user.permissions.screeningQuestions", value: "screeningQuestions" },
  { label: "administrator.user.permissions.tenantTags", value: "tenantTags" },
  { label: "administrator.user.permissions.calendars", value: "calendars" },
  { label: "administrator.user.permissions.clients", value: "clients" },
  { label: "administrator.user.permissions.variables", value: "variables" },
  { label: "administrator.user.permissions.operational", value: "operational" },
  { label: "administrator.user.permissions.categoryTag", value: "categoryTag" },
  { label: "administrator.user.permissions.screeningAttributes", value: "screeningAttributes" },
  { label: "administrator.user.permissions.cobrands", value: "cobrands" },
  { label: "administrator.user.permissions.externalSystemOperations", value: "externalSystemOperations" },
  { label: "administrator.user.permissions.referenceCheck", value: "referenceCheck" },
  { label: "administrator.user.permissions.jobdescriptionsections", value: "jobdescriptionsections" },
  { label: "administrator.user.permissions.jobDescriptionBoards", value: "jobDescriptionBoards" },
  { label: "administrator.user.permissions.budget", value: "budget" },
  { label: "administrator.user.permissions.userFeedback", value: "userFeedback" },
  { label: "administrator.user.permissions.userProfileModules", value: "userProfileModules" },
  { label: "administrator.user.permissions.offerTemplates", value: "offerTemplates" },
  { label: "administrator.user.permissions.notification", value: "notification" },
  { label: "administrator.user.permissions.releaseNotes", value: "releaseNotes" },
  { label: "administrator.user.permissions.additionalDocuments", value: "additionalDocuments" },
  { label: "administrator.user.permissions.conversionRates", value: "conversionRates" },
];

export enum USER_PROFILE_TYPES {
  user = "user",
  viewOnly = "user-view-only",
  talentAttractionSpecialist = "user-talent-attraction-specialist",
  talentSourcingSpecialist = "user-talent-sourcing-specialist",
  talentScreeningSpecialist = "user-talent-screening-specialist",
  talentAssessmentSpecialist = "user-talent-assessment-specialist",
  talentAssessmentSeniorSpecialist = "user-talent-assessment-senior-specialist",
  allProfiles = "all-profiles",
  fullDeskRecruiter = "Full Desk Recruiter",
}

export enum LeadStatus {
  New = "New",
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Deleted = "Deleted",
}

export const USER_PROFILES = [
  { name: "Full Desk Recruiter", value: USER_PROFILE_TYPES.user },
  { name: "View Only", value: USER_PROFILE_TYPES.viewOnly },
  { name: "Talent Attraction Specialist", value: USER_PROFILE_TYPES.talentAttractionSpecialist },
  { name: "Talent Sourcing Specialist", value: USER_PROFILE_TYPES.talentSourcingSpecialist },
  { name: "Talent Screening Specialist", value: USER_PROFILE_TYPES.talentScreeningSpecialist },
  { name: "Talent Assessment Specialist", value: USER_PROFILE_TYPES.talentAssessmentSpecialist },
  { name: "Talent Assessment Senior Specialist", value: USER_PROFILE_TYPES.talentAssessmentSeniorSpecialist },
];

export const PHASE_PROFILES = [
  { name: "Talent Attraction Specialist", value: USER_PROFILE_TYPES.talentAttractionSpecialist },
  { name: "Talent Sourcing Specialist", value: USER_PROFILE_TYPES.talentSourcingSpecialist },
  { name: "Talent Screening Specialist", value: USER_PROFILE_TYPES.talentScreeningSpecialist },
  { name: "Talent Assessment Specialist", value: USER_PROFILE_TYPES.talentAssessmentSpecialist },
  { name: "Talent Assessment Senior Specialist", value: USER_PROFILE_TYPES.talentAssessmentSeniorSpecialist },
];

export const DEFAULT_LANGUAGE = "en-US";
export const SEPARATOR_COSUBBRAND = "_";
export const PREFIX_COBRAND = "co";
export const PREFIX_SUBBRAND = "sub";
export const PREFIX_SUBBRAND_SEPARTOR = PREFIX_SUBBRAND + SEPARATOR_COSUBBRAND;
export const PREFIX_COBRAND_SEPARTOR = PREFIX_COBRAND + SEPARATOR_COSUBBRAND;
export const TIMEZONES = {
  "(GMT-11:00) Pago Pago": "Pacific/Pago_Pago",
  "(GMT-10:00) Hawaii Time": "Pacific/Honolulu",
  "(GMT-08:00) Alaska Time": "America/Juneau", // Added for external integrations
  "(GMT-08:00) Pacific Time": "America/Los_Angeles",
  "(GMT-08:00) Pacific Time - Tijuana": "America/Tijuana",
  "(GMT-07:00) Mountain Time": "America/Denver",
  "(GMT-07:00) Mountain Time - Arizona": "America/Phoenix",
  "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan": "America/Mazatlan",
  "(GMT-06:00) Central Time": "America/Chicago",
  "(GMT-06:00) Central Time - Mexico City": "America/Mexico_City",
  "(GMT-06:00) Central Time - Regina": "America/Regina",
  "(GMT-06:00) Guatemala": "America/Guatemala",
  "(GMT-05:00) Bogota": "America/Bogota",
  "(GMT-05:00) Eastern Time": "America/New_York",
  "(GMT-05:00) Lima": "America/Lima",
  "(GMT-04:30) Caracas": "America/Caracas",
  "(GMT-04:00) Atlantic Time - Halifax": "America/Halifax",
  "(GMT-04:00) Guyana": "America/Guyana",
  "(GMT-04:00) La Paz": "America/La_Paz",
  "(GMT-03:00) Buenos Aires": "America/Argentina/Buenos_Aires",
  "(GMT-03:00) Godthab": "America/Godthab",
  "(GMT-03:00) Montevideo": "America/Montevideo",
  "(GMT-03:30) Newfoundland Time - St. Johns": "America/St_Johns",
  "(GMT-03:00) Santiago": "America/Santiago",
  "(GMT-02:00) Sao Paulo": "America/Sao_Paulo",
  "(GMT-02:00) South Georgia": "Atlantic/South_Georgia",
  "(GMT-01:00) Azores": "Atlantic/Azores",
  "(GMT-01:00) Cape Verde": "Atlantic/Cape_Verde",
  "(GMT+00:00) Casablanca": "Africa/Casablanca",
  "(GMT+00:00) Dublin": "Europe/Dublin",
  "(GMT+00:00) Lisbon": "Europe/Lisbon",
  "(GMT+00:00) London": "Europe/London",
  "(GMT+00:00) Monrovia": "Africa/Monrovia",
  "(GMT+01:00) Algiers": "Africa/Algiers",
  "(GMT+01:00) Amsterdam": "Europe/Amsterdam",
  "(GMT+01:00) Berlin": "Europe/Berlin",
  "(GMT+01:00) Brussels": "Europe/Brussels",
  "(GMT+01:00) Budapest": "Europe/Budapest",
  "(GMT+01:00) Central European Time - Belgrade": "Europe/Belgrade",
  "(GMT+01:00) Central European Time - Prague": "Europe/Prague",
  "(GMT+01:00) Copenhagen": "Europe/Copenhagen",
  "(GMT+01:00) Madrid": "Europe/Madrid",
  "(GMT+01:00) Paris": "Europe/Paris",
  "(GMT+01:00) Rome": "Europe/Rome",
  "(GMT+01:00) Stockholm": "Europe/Stockholm",
  "(GMT+01:00) Vienna": "Europe/Vienna",
  "(GMT+01:00) Warsaw": "Europe/Warsaw",
  "(GMT+02:00) Athens": "Europe/Athens",
  "(GMT+02:00) Bucharest": "Europe/Bucharest",
  "(GMT+02:00) Cairo": "Africa/Cairo",
  "(GMT+02:00) Jerusalem": "Asia/Jerusalem",
  "(GMT+02:00) Johannesburg": "Africa/Johannesburg",
  "(GMT+02:00) Helsinki": "Europe/Helsinki",
  "(GMT+02:00) Kiev": "Europe/Kiev",
  "(GMT+02:00) Moscow-01 - Kaliningrad": "Europe/Kaliningrad",
  "(GMT+02:00) Riga": "Europe/Riga",
  "(GMT+02:00) Sofia": "Europe/Sofia",
  "(GMT+02:00) Tallinn": "Europe/Tallinn",
  "(GMT+02:00) Vilnius": "Europe/Vilnius",
  "(GMT+03:00) Istanbul": "Europe/Istanbul",
  "(GMT+03:00) Baghdad": "Asia/Baghdad",
  "(GMT+03:00) Nairobi": "Africa/Nairobi",
  "(GMT+03:00) Minsk": "Europe/Minsk",
  "(GMT+03:00) Riyadh": "Asia/Riyadh",
  "(GMT+03:00) Moscow+00 - Moscow": "Europe/Moscow",
  "(GMT+03:30) Tehran": "Asia/Tehran",
  "(GMT+04:00) Baku": "Asia/Baku",
  "(GMT+04:00) Moscow+01 - Samara": "Europe/Samara",
  "(GMT+04:00) Tbilisi": "Asia/Tbilisi",
  "(GMT+04:00) Yerevan": "Asia/Yerevan",
  "(GMT+04:30) Kabul": "Asia/Kabul",
  "(GMT+05:00) Karachi": "Asia/Karachi",
  "(GMT+05:00) Moscow+02 - Yekaterinburg": "Asia/Yekaterinburg",
  "(GMT+05:00) Tashkent": "Asia/Tashkent",
  "(GMT+05:30) Colombo": "Asia/Colombo",
  "(GMT+06:00) Almaty": "Asia/Almaty",
  "(GMT+06:00) Dhaka": "Asia/Dhaka",
  "(GMT+06:30) Rangoon": "Asia/Rangoon",
  "(GMT+07:00) Bangkok": "Asia/Bangkok",
  "(GMT+07:00) Jakarta": "Asia/Jakarta",
  "(GMT+07:00) Moscow+04 - Krasnoyarsk": "Asia/Krasnoyarsk",
  "(GMT+08:00) China Time - Beijing": "Asia/Shanghai",
  "(GMT+08:00) Hong Kong": "Asia/Hong_Kong",
  "(GMT+08:00) Kuala Lumpur": "Asia/Kuala_Lumpur",
  "(GMT+08:00) Moscow+05 - Irkutsk": "Asia/Irkutsk",
  "(GMT+08:00) Singapore": "Asia/Singapore",
  "(GMT+08:00) Taipei": "Asia/Taipei",
  "(GMT+08:00) Ulaanbaatar": "Asia/Ulaanbaatar",
  "(GMT+08:00) Western Time - Perth": "Australia/Perth",
  "(GMT+09:00) Moscow+06 - Yakutsk": "Asia/Yakutsk",
  "(GMT+09:00) Seoul": "Asia/Seoul",
  "(GMT+09:00) Tokyo": "Asia/Tokyo",
  "(GMT+09:30) Central Time - Darwin": "Australia/Darwin",
  "(GMT+10:00) Eastern Time - Brisbane": "Australia/Brisbane",
  "(GMT+10:00) Guam": "Pacific/Guam",
  "(GMT+10:00) Moscow+07 - Magadan": "Asia/Magadan",
  "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk": "Asia/Vladivostok",
  "(GMT+10:00) Port Moresby": "Pacific/Port_Moresby",
  "(GMT+10:30) Central Time - Adelaide": "Australia/Adelaide",
  "(GMT+11:00) Eastern Time - Hobart": "Australia/Hobart",
  "(GMT+11:00) Eastern Time - Melbourne, Sydney": "Australia/Sydney",
  "(GMT+11:00) Guadalcanal": "Pacific/Guadalcanal",
  "(GMT+11:00) Noumea": "Pacific/Noumea",
  "(GMT+12:00) Majuro": "Pacific/Majuro",
  "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy": "Asia/Kamchatka",
  "(GMT+13:00) Auckland": "Pacific/Auckland",
  "(GMT+13:00) Fakaofo": "Pacific/Fakaofo",
  "(GMT+13:00) Fiji": "Pacific/Fiji",
  "(GMT+13:00) Tongatapu": "Pacific/Tongatapu",
  "(GMT+14:00) Apia": "Pacific/Apia",
};

export const INPUT_FIELD_DELAY = { veryShort: 288, short: 640, medium: 1024 };
export const START_VALIDATE_YEAR = 1900;
export const defaultFiltersResults = { page: 1, perPage: 10 };
export const DEBOUNCE_TIME = 500;
export const MAX_AGE_COOKIE = 60 * 60 * 24;
export const MAX_AGE_PROCESS_STORAGE = 8 * 60 * 60 * 1000;

// Filter funneling stats
export const filterTable = [
  { value: "all", label: "All" },
  { value: "inbound", label: "Inbound" },
  { value: "outbound", label: "Outbound" },
];

// Filter funneling stats
export const filterUtmSouces = [
  { value: "all", label: "All" },
  { value: "Facebook", label: "Facebook" },
  { value: "LinkedIn", label: "Linkedin" },
  { value: "Twitter", label: "Twitter" },
  { value: "AdditionalSources", label: "funelling.AdditionalSources" },
];

export const activeUtmProperties = ["utm_source", "utm_campaign", "utm_medium"];

export interface UtmTags {
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
}

// INFO MAPING
export const FOCORE_RECEIVED_COUNTRY = "AF";
export const FOCORE_RECEIVED_BRAND = "Adecco";
export const FOCORE_SEND_COUNTRY = "FC";
export const FOCORE_SEND_BRAND = "FOCORE";
export const FOCORE_TENANT = 88;

export interface ResultsI {
  count: number;
  data: any;
}

export interface TypedResultsI<T> {
  count: number;
  data: T[];
}

export interface FiltersResults {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sortDirection?: string;
  budgetYear?: number;
  [others: string]: any;
}

export interface IProviderInfo {
  name: string;
  email: string;
  phone: string;
  reference: string;
}

export const FAQS_SECTION = "FAQs";

export const CONTACT_PROVIDER_ME = "me";
export const CONTACT_PROVIDER_OTHER = "other";
export const CONTACT_PROVIDER_BRANCH = "branch";

export const DESCRIPTION_TEMPLATE = "template";
export const DESCRIPTION_TEMPLATE_SMART_RECOMMENDATION = "smartRecommendation";
export const DESCRIPTION_TEMPLATE_JD_GENERATOR = "jobDescriptionGenerator";
export const DESCRIPTION_SECTIONS_SEARCH = "templateSearch";

export const DEFAULT_FOR_TEMPLATE = "jobDescriptionLanguage";
export const DEFAULT_FOR_PERSONAL_TEMPLATE = "personalTemplate";
export const DEFAULT_FOR_SMART_RECOMMENDATION = "jobDescriptionRecommendation";

export * from "./fontHelperJSPDF";

export const processesPhases = [
  { name: "", title: "All process phases" },
  { name: "0", title: "Phase.type.name.0" }, // Phase type video must be "0" instead of 0 because the input doesn't accept nullish values
  { name: PhaseType.screeningQuestions, title: "Phase.type.name.1" },
  { name: PhaseType.videoInterview, title: "Phase.type.name.2" },
  { name: PhaseType.liveInterview, title: "Phase.type.name.3" },
  { name: PhaseType.hardSkill, title: "Phase.type.name.4" },
  { name: PhaseType.softSkill, title: "Phase.type.name.5" },
  { name: PhaseType.phoneCall, title: "Phase.type.name.6" },
  { name: PhaseType.candidatePresentation, title: "Phase.type.name.7" },
  { name: PhaseType.shortlist, title: "Phase.type.name.8" },
  { name: PhaseType.reference, title: "creator.card.reference.check" },
  { name: PhaseType.offerManagement, title: "Phase.type.name.10" },
];

interface DateFormat {
  [key: number]: DateFormatElement;
  default: DateFormatElement;
}
export enum DateFormatDisplayTypes {
  numericDay = "numericDay",
  stringDay = "stringDay",
  stringMonth = "stringMonth",
}
type DateFormatElement = {
  [key in DateFormatDisplayTypes]: string;
} & {
  regionCode: number;
  regionName: string;
  dayOrder: number;
  monthOrder: number;
  yearOrder: number;
};

export const DATE_FORMAT: DateFormat = {
  //same data in the Backend <Datehelper.ts>
  1: { regionCode: 1, regionName: "US", numericDay: "MM/DD/yyyy", stringDay: "MM/dd/yyyy", stringMonth: "MMMM DD YYYY", dayOrder: 2, monthOrder: 1, yearOrder: 3 },
  2: { regionCode: 2, regionName: "WORLD", numericDay: "DD/MM/yyyy", stringDay: "dd/MM/yyyy", stringMonth: "DD MMMM YYYY", dayOrder: 1, monthOrder: 2, yearOrder: 3 },
  3: { regionCode: 3, regionName: "JAPAN", numericDay: "yyyy/MM/DD", stringDay: "yyyy/MM/dd", stringMonth: "YYYY MMMM DD", dayOrder: 3, monthOrder: 2, yearOrder: 1 },
  default: { regionCode: 1, regionName: "US", numericDay: "MM/DD/yyyy", stringDay: "MM/dd/yyyy", stringMonth: "MMMM DD YYYY", dayOrder: 2, monthOrder: 1, yearOrder: 3 },
};

export enum APP_MACRO_ROLES {
  RECRUITER = "Recruiter",
  CANDIDATE = "Candidate",
  REVIEWER = "Reviewer",
}

export const offerManagementVariables = [
  { name: "Shifts available", value: "availableShifts" },
  { name: "Pay rate value", value: "payRateRate" },
  { name: "Pay rate measurement", value: "payRateMeasurement" },
  { name: "Work location", value: "workLocation" },
  { name: "Special conditions", value: "specialConditions" },
  { name: "On boarding link", value: "onBoardingLink" },
  { name: "Validity period", value: "validityPeriod" },
  { name: "Currency", value: "currency" },
];

export interface FiltersProcessDetails {
  id: string;
  page: number;
  perPage: number;
  order: any;
  search: string;
  tagFilter: any;
  filterTagsType: string;
  filterPhases: any[];
  filterAnyPending: boolean;
  filterDate?: boolean;
  searchField?: string;
  initDate?: number;
  endDate?: number;
  screeningFilter?: any[];
  date?: string;
  [others: string]: any;
}

export const scheduleTime = [
  { label: "00:00", value: "00:00:00" },
  { label: "00:30", value: "00:30:00" },
  { label: "01:00", value: "01:00:00" },
  { label: "01:30", value: "01:30:00" },
  { label: "02:00", value: "02:00:00" },
  { label: "02:30", value: "02:30:00" },
  { label: "03:00", value: "03:00:00" },
  { label: "03:30", value: "03:30:00" },
  { label: "04:00", value: "04:00:00" },
  { label: "04:30", value: "04:30:00" },
  { label: "05:00", value: "05:00:00" },
  { label: "05:30", value: "05:30:00" },
  { label: "06:00", value: "06:00:00" },
  { label: "06:30", value: "06:30:00" },
  { label: "07:00", value: "07:00:00" },
  { label: "07:30", value: "07:30:00" },
  { label: "08:00", value: "08:00:00" },
  { label: "08:30", value: "08:30:00" },
  { label: "09:00", value: "09:00:00" },
  { label: "09:30", value: "09:30:00" },
  { label: "10:00", value: "10:00:00" },
  { label: "10:30", value: "10:30:00" },
  { label: "11:00", value: "11:00:00" },
  { label: "11:30", value: "11:30:00" },
  { label: "12:00", value: "12:00:00" },
  { label: "12:30", value: "12:30:00" },
  { label: "13:00", value: "13:00:00" },
  { label: "13:30", value: "13:30:00" },
  { label: "14:00", value: "14:00:00" },
  { label: "14:30", value: "14:30:00" },
  { label: "15:00", value: "15:00:00" },
  { label: "15:30", value: "15:30:00" },
  { label: "16:00", value: "16:00:00" },
  { label: "16:30", value: "16:30:00" },
  { label: "17:00", value: "17:00:00" },
  { label: "17:30", value: "17:30:00" },
  { label: "18:00", value: "18:00:00" },
  { label: "18:30", value: "18:30:00" },
  { label: "19:00", value: "19:00:00" },
  { label: "19:30", value: "19:30:00" },
  { label: "20:00", value: "20:00:00" },
  { label: "20:30", value: "20:30:00" },
  { label: "21:00", value: "21:00:00" },
  { label: "21:30", value: "21:30:00" },
  { label: "22:00", value: "22:00:00" },
  { label: "22:30", value: "22:30:00" },
  { label: "23:00", value: "23:00:00" },
  { label: "23:30", value: "23:30:00" },
];

export enum AAMBE_ENVIRONMENT {
  a = "di.dev",
  d = "di.dev",
  t = "di.test",
  u = "di.bat",
  p = "di.prod",
}

export const phases = [
  {
    name: "creator.card.video",
    type: 0,
    isRunByCA: false,
  },
  {
    name: "creator.card.screening",
    type: 1,
    isRunByCA: false,
  },
  {
    name: "screening.ca.chatbot",
    type: 1,
    isRunByCA: true,
  },
  {
    name: "creator.card.videointerview",
    type: 2,
    isRunByCA: false,
  },
  {
    name: "home.live",
    type: 3,
    isRunByCA: false,
  },
  {
    name: "creator.process.card.hard.skills",
    type: 4,
    isRunByCA: false,
  },
  {
    name: "creator.process.card.soft.skills",
    type: 5,
    isRunByCA: false,
  },
  {
    name: "creator.card.phoneCall",
    type: 6,
    isRunByCA: false,
  },
  {
    name: "candidate.presentation",
    type: 7,
    isRunByCA: false,
  },
  {
    name: "creator.card.shortlist",
    type: 8,
    isRunByCA: false,
  },
  {
    name: "creator.card.reference.check",
    type: 9,
    isRunByCA: false,
  },
  {
    name: "creator.card.offerManagement",
    type: 10,
    isRunByCA: false,
  },
];

export enum NotificationDataType {
  Email = "email",
  Sms = "sms",
}

export interface NotificationsData {
  type: NotificationDataType;
  active: boolean;
  templateId: string;
  customMessage: string;
  automaticReminder: boolean;
  automaticCount: number | null;
  frequency: number | null;
  reminderTemplateId: string;
  reminderCustomMessage: string;
  multilanguage?: any;
  CAInstanceId?: string;
}

export interface DocumentsData {
  label: string;
  mandatory?: boolean;
}

export enum TagCategories {
  ProcessTags = "00000000-0000-0000-0000-000000000001",
  JobTags = "00000000-0000-0000-0000-000000000002",
  ClientTags = "00000000-0000-0000-0000-000000000003",
}

export const CAMMIO_BRANDING_ID_ADECCO_THEME = { DEV: 15202, LOCAL: 15202, PROD: 19847, UAT: 19798, TEST: 15342 };
