import React, { ReactElement, useState } from "react";
import { TextInput } from "../../../../shared/forms";
import { CopilotActionSteps } from "../copilot-actions";
import { toLiteral } from "../../../../../helper/locale-utils";

/**
 * Component that renders the text input answer type
 */
const TextInputAnswer: React.FC<any> = (props: any): ReactElement => {
  const { dialogStep, triggerAction } = props;

  const [inputValue, setInputValue] = useState();

  function handleSubmit(nextStepId?: string) {
    triggerAction(inputValue, nextStepId);
  }

  const renderAnswerContainer = (_dialogStep: CopilotActionSteps) => {
    if (Array.isArray(_dialogStep?.answers)) {
      const answerConfig = _dialogStep?.answers?.[0];
      return (
        <React.Fragment>
          <TextInput name={_dialogStep?.id} placeholder={answerConfig?.label} type="text" onChange={(e: any) => setInputValue(e?.target?.value)} />
          <button className="button-icon ml1" disabled={!inputValue} onClick={() => handleSubmit(answerConfig?.nextStep)}>
            <span className="material-icons">send</span>
          </button>
        </React.Fragment>
      );
    } else {
      return toLiteral({ id: "Sorry, something went wrong when loading the answer selector" });
    }
  };

  return <div className="flex">{renderAnswerContainer(dialogStep)}</div>;
};

export default TextInputAnswer;
