import * as Api from "../../../../../api";
import { getFeatureConfiguration, getFeatureConfigurationIsEnabled } from "../../../../../helper/feature-configuration-helper";
import store from "../../../../../redux/store";
import { generateShowNameBranch } from "../../../../../helper/app-helper";
import { CURRENT_LOCALE, getLocaleImage } from "../../../../../helper/locale-utils";
import { TagCategories } from "../../../../../constants";

// Save response as a cache
export let smsTemplatesResponse: any;
export async function getSMSTemplatesOptions(_: string) {
  try {
    let response: any;
    if (!smsTemplatesResponse) {
      response = await Api.getAllNotificationTemplates({});
      smsTemplatesResponse = response;
    } else response = smsTemplatesResponse;
    return response?.data?.NotificationTemplate?.filter((_template: any) => _template.type === "sms").map((smsTemplate: any) => {
      return { key: smsTemplate?.id, label: smsTemplate?.title, data: smsTemplate };
    });
  } catch (error) {
    console.error(error);
  }
}

export async function getAllBrandingsOptions(configKey: string) {
  try {
    let response: any[];
    switch (configKey) {
      case "subBrandId":
        const responseSubBrand = await Api.getAllBrands();
        response = responseSubBrand?.filter((subBrand: any) => subBrand.officialName !== null && subBrand.officialName !== "");
        break;
      case "cobrandId":
        const responseCobrands = await Api.getAllCoBrands();
        response = responseCobrands?.filter((cobrands: any) => cobrands.officialName !== null && cobrands.officialName !== "");
        break;
      case "clientBrandId":
        const responseClientBrands = await Api.fetchClientBrands();
        response = responseClientBrands?.[0]?.filter((clientBrand: any) => clientBrand.officialName !== null && clientBrand.officialName !== "") as any[];
        break;
      default:
        response = [];
        break;
    }
    if (!response) return [];
    return response?.map((result: any) => {
      return { key: result?.id, label: result?.officialName, ...result };
    });
  } catch (error) {
    console.error(error);
  }
}

// Save response as a cache
let tagsOptionsResponse: any;
export async function getTenantTagsOptions(configKey: string) {
  try {
    let response;
    if (!tagsOptionsResponse) {
      response = await Api.getProcessCategoryTags();
      tagsOptionsResponse = response;
    } else response = tagsOptionsResponse;
    let tagsOptions: any[] = [];
    switch (configKey) {
      case "clientTags":
        tagsOptions = response.find((item: any) => item.id === TagCategories.ClientTags)?.tags;
        break;
      case "processTags":
        tagsOptions = response.find((item: any) => item.id === TagCategories.ProcessTags)?.tags;
        break;
      case "jobTags":
        tagsOptions = response.find((item: any) => item.id === TagCategories.JobTags)?.tags;
        break;
      default:
        break;
    }
    if (!response || !tagsOptions) return [];
    return tagsOptions?.map((tag: any) => {
      return {
        key: tag?.tenantTag?.id,
        label: tag?.tenantTag?.tenantTagLanguage?.name || tag?.name,
        default: tag?.isStandard,
      };
    });
  } catch (error) {
    console.error(error);
  }
}

// Save response as a cache
export let offerTemplatesResponse: any;
export async function getOfferTemplatesOptions(_: string) {
  try {
    let response: any;
    if (!offerTemplatesResponse) {
      response = await Api.fetchOfferTemplates();
      offerTemplatesResponse = response;
    } else response = offerTemplatesResponse;
    return response?.offerTemplates?.map((offerTemplate: any) => {
      return { key: offerTemplate?.id, label: offerTemplate?.name, data: offerTemplate };
    });
  } catch (error) {
    console.error(error);
  }
}

// Save response as a cache
let calendarsResponse: any;
export async function getAllCalendarsOptions(_: string) {
  try {
    const tenant = store.getState()?.tenant?.selectedTenant;
    let response: any[];
    if (!calendarsResponse) {
      response = await Api.fetchProcessCalendars(tenant?.regionId, tenant?.zoneId, tenant?.branchId);
      calendarsResponse = response;
    } else response = calendarsResponse;
    if (!response) return [];
    return response?.map((result: any) => {
      return { key: result?.id, label: result?.name };
    });
  } catch (error) {
    console.error(error);
  }
}

// Save response as a cache
let emails: any;
export async function getReplyToEmails(_: string) {
  try {
    const tenant = store.getState()?.tenant?.selectedTenant;
    let response: any[];
    if (!emails) {
      response = await Api.getReplyToEmails(tenant?.alias, { branchId: tenant.branchId });
      emails = response;
    } else response = emails;
    if (!response) return [];
    return response?.map((result: any) => {
      return { key: result?.address, label: result?.address };
    });
  } catch (error) {
    console.error(error);
  }
}

// Save response as a cache
let users: any;
export async function getAllUsers(_: string) {
  try {
    const tenant = store.getState()?.tenant?.selectedTenant;
    let response: any[];
    if (!users) {
      response = await Api.fetchUsersBranch(tenant?.regionId || "", tenant?.zoneId || "", tenant?.branchId || "");
      users = response;
    } else response = users;
    if (!response) return [];
    return response?.map((result: any) => {
      return { key: result?.id, label: result?.name };
    });
  } catch (error) {
    console.error(error);
  }
}

// Save response as a cache
export let skillsResponse: any;
export async function getAllSkillsOptions() {
  try {
    let response = await Api.fetchSkillList("");
    skillsResponse = { [CURRENT_LOCALE]: response };
    return response?.skills?.map((result: any) => {
      return { key: result?.id, label: result?.localeName || result?.name };
    });
  } catch (error) {
    console.error(error);
  }
}

export async function getTenantBranches() {
  const selectedTenant = store?.getState()?.tenant?.selectedTenant;

  return selectedTenant?.branches?.map((_branch: any) => {
    return { key: _branch?.branchId, label: generateShowNameBranch(_branch?.branchName, _branch?.costCenter) };
  });
}

// Get currency types available in feature conf tenant.
export async function getCurrencyTypesFromTenant(_: string) {
  const currencyResume = getFeatureConfiguration("Process Phases", "offer-management");
  const currencies = currencyResume?.config["offer-management-currency"] || [];
  const currenciesSelector = currencies.map((currency: string) => {
    return { key: currency, label: currency };
  });
  return currenciesSelector;
}

// Get associated client
let associatedClients: any;
export async function getAssociatedClientsOptions(_: string, formSate: any) {
  const clientsFilterByBranch = getFeatureConfigurationIsEnabled("General Settings", "clients-filter-by-branch");
  const branchId = clientsFilterByBranch ? store.getState().tenant.selectedTenant.branchId : undefined;

  if (associatedClients) {
    return associatedClients;
  } else {
    const response = await Api.fetchAdminClientsList("", false, branchId);
    associatedClients = response.clients.map((_client: any) => {
      return { key: _client.id, label: _client.name };
    });
    return associatedClients;
  }
}

export async function getAvailableLanguages(_: string) {
  const developerFlag = store.getState()?.tenant?.userProperties?.developerFlag === true;

  const availableLanguages = getFeatureConfiguration("Process Creation", "process-languages");
  const screeningQuestionLibrary = getFeatureConfiguration("General Settings", "screening-questions-library");
  if (!availableLanguages?.isEnabled || !screeningQuestionLibrary) return [];

  const languages = availableLanguages?.config?.processLanguages || [];
  const languagesSelector = languages.map((lang: { isoCode: string; name: string }) => {
    return {
      key: lang.isoCode,
      label: lang.name,
      others: { image: developerFlag ? { src: getLocaleImage(lang?.isoCode), className: "country-flag" } : null },
    };
  });
  return languagesSelector;
}
