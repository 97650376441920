import {
  ADMIN_SECTION,
  CANDIDATE_SOURCING_MODULE,
  CANDIDATE_SOURCING_SECTION,
  HOME_SECTION,
  RECRUITMENT_CONNECT_MODULE,
  RECRUITMENT_CONNECT_SECTION,
  ROLE_TENANT_ADMIN,
  ROLE_GLOBAL_ADMIN,
} from "../../../constants";

interface HoverOption {
  name: string;
  redirect: string;
  title: string;
  feature?: any;
}
export interface SidebarOption {
  icon: string;
  title: string;
  redirect: string;
  modules: string[];
  roles?: string[];
  section?: string;
  feature?: any;
  optionsHover?: HoverOption[];
}

export const SIDEBAR_LIST: SidebarOption[] = [
  {
    icon: "home",
    title: "sidebar.home",
    redirect: "RECRUITER_PORTAL",
    modules: [CANDIDATE_SOURCING_MODULE, RECRUITMENT_CONNECT_MODULE],
    section: HOME_SECTION,
    feature: {
      channel: "General Settings",
      feature: "recruiter-portal",
    },
  },
  {
    icon: "initFlag",
    title: "sidebar.recruitment",
    redirect: "RECRUITMENT",
    modules: [RECRUITMENT_CONNECT_MODULE],
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    icon: "jobDescription",
    title: "sidebar.sourcing",
    redirect: "JOB_LISTING",
    modules: [CANDIDATE_SOURCING_MODULE],
    section: CANDIDATE_SOURCING_SECTION,
    optionsHover: [
      {
        name: "Job Descriptions",
        title: "sourcing",
        redirect: "JOB_LISTING",
      },
      {
        name: "Proactive Sourcing",
        title: "proactive-sourcing",
        redirect: "PROACTIVE_SOURCING",
        feature: {
          channel: "Candidate Sourcing Settings",
          feature: "proactive-sourcing-tab",
        },
      },
      {
        name: "Job Posting",
        title: "job-posting",
        redirect: "JOB_POSTING_LISTING",
        feature: {
          channel: "Candidate Sourcing Settings",
          feature: "job-posting-tab",
        },
      },
    ],
  },
  // {
  //   icon: "chat",
  //   title: "sidebar.carrer.assistant",
  //   redirect: "",
  //   modules: [CANDIDATE_SOURCING_MODULE, RECRUITMENT_CONNECT_MODULE],
  // },
  // {
  //   icon: "test",
  //   title: "sidebar.xpert.assessments",
  //   redirect: "",
  //   modules: [CANDIDATE_SOURCING_MODULE, RECRUITMENT_CONNECT_MODULE],
  // },
  {
    icon: "settings",
    title: "sidebar.admin.settings",
    redirect: "ADMINISTRATOR_HOME",
    modules: [CANDIDATE_SOURCING_MODULE, RECRUITMENT_CONNECT_MODULE],
    roles: [ROLE_GLOBAL_ADMIN, ROLE_TENANT_ADMIN],
    section: ADMIN_SECTION,
  },
];
